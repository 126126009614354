// todo 9550 move to lib
@use 'theming/src/styles/pallete' as pallete;
@use '3rd-party/angular-tree-component';

.drop-zone,
.cdk-drop-list {
  border: 1px solid transparent;

  &.is-dragging-over,
  &.cdk-drop-list-dragging {
    border: 1px solid pallete.$vida-primary;
  }

  &.is-dragging-over-disabled {
    border: 1px solid pallete.$alert;
  }
}

.player {
  .player__video {
    .omakase-video {
      width: 100%;
    }
  }
}

// todo 9550 move to component
div.policies-list .policies-list__policy-default-for .value-group .pill {
  padding: 0.2rem 0.5rem;
  font-size: 0.6rem;
}
