@mixin apply() {
  .cdk-overlay-container .mat-mdc-dialog-container {
    // apply border
    .mat-mdc-dialog-surface {
      outline: 1px solid var(--divider, #2e3138);
      border-radius: var(--radius-md);
    }

    .dialog-wrapper-header {
      &::before {
        display: none;
      }

      h1 {
        margin-bottom: 0;
      }
    }

    .mdc-dialog__content {
      //override dialog font styles:
      --mdc-dialog-supporting-text-color: var(--text);
      --mdc-dialog-supporting-text-line-height: 20px;
      --mdc-dialog-supporting-text-size: 14px;
      --mdc-dialog-supporting-text-weight: 400;
      --mdc-dialog-supporting-text-tracking: 0.0179em;
    }

    .mdc-dialog__title + .mdc-dialog__content,
    .mdc-dialog__header + .mdc-dialog__content {
      padding: 20px 24px 20px 24px;
      --page-table-padding: 0;
    }
  }

  // apply backdrop filter
  .cdk-overlay-backdrop:not(.mat-overlay-transparent-backdrop) {
    backdrop-filter: blur(10px);

    &.cdk-overlay-dark-backdrop {
      background: #1516194d;
    }
  }
}
