@use 'theming/src/styles/pallete' as pallete;
@import '@circlon/angular-tree-component/css/angular-tree-component.css'; // todo 9550 move to lib ?

.tree-container {
  .tree-node-loading span {
    display: flex;
    justify-content: center;
    color: var(--disabled-text, pallete.$light-20);
  }
  .node-wrapper {
    position: relative;
    margin: 3px 5px;
    border-radius: 2px;
    padding: 2px;
    align-items: center;
    .toggle-children-wrapper {
      padding: 0 2px;
      .toggle-children {
        top: -1px;
        filter: contrast(0);
      }
    }

    &:hover {
      background: var(--tree-item-bg-hover, pallete.$dark-20);
      color: var(--tree-item-fg-hover, pallete.$white);
    }

    .node-content-wrapper {
      border-radius: 0;
      padding: 0;
      border: 1px transparent dotted;
      width: 100%;
      &:hover {
        background: none;
        box-shadow: none;
      }
    }

    .node-content-wrapper-focused {
      background: none;
      box-shadow: none;
      color: var(--tree-focus, pallete.$vida-primary);
      .node-icon {
        color: var(--tree-focus, pallete.$vida-primary);
      }
    }
  }

  .tree-node {
    background: none;
    .node-icon {
      margin-right: 3px;
    }
    .node-label {
      white-space: nowrap;
    }
    .node-actions {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      opacity: 0;
    }

    &:hover .node-actions {
      opacity: 1;
    }
  }
  .node-content-wrapper,
  .node-drop-slot {
    &.is-dragging-over {
      background: #3573ff0f;
      border: 1px solid pallete.$vida-primary;
      border-radius: 2px;
      background: none;
    }
    &.is-dragging-over-disabled {
      background: rgba(255, 53, 53, 0.06);
      border: 1px solid pallete.$alert;
      opacity: 1;
      cursor: not-allowed;
    }
  }
}
