.omakase-player {
  display: block;

  .d-none {
    display: none;
  }

  .d-block {
    display: block;
  }
}

.omakase-timeline-overlay {
  position: absolute;
  z-index: 100;

  .omakase-timeline-timecode {
    position: absolute;
    padding-left: 10px;
    padding-top: 20px;
    overflow: hidden;
    pointer-events: none;
  }
}
