@use '../media-queries';
@use 'theming/src/styles/pills' as *;

@mixin apply() {
  .material-custom-table.mat-mdc-table {
    width: 100%;
    overflow: auto;
    border-collapse: separate;
    border-spacing: var(--table-border-spacing-x, 0) var(--table-border-spacing-y, 0);

    .mat-mdc-row,
    .mat-mdc-table-sticky {
      background: var(--table-bg, var(--bg-second));
    }

    .mat-mdc-header-cell {
      color: var(--table-header-text, var(--text));
    }

    .mat-mdc-footer-cell,
    .mat-mdc-cell {
      color: var(--table-text);

      em {
        color: var(--table-higlighted, var(--primary));
      }
    }

    tr {
      height: 48px;

      & td:has(.mat-mdc-checkbox-checked) {
        & ~ td {
          color: var(--table-text-selected);
        }
      }
    }

    tbody {
      td {
        border: none;
      }

      td:not(:first-of-type) {
        border-left: 1px solid var(--table-column-divider);
      }

      tr:not(:first-of-type) td {
        border-top: 2px solid var(--table-row-divider, var(--bg-first));
      }

      tr:hover td:not(:first-of-type) {
        border-left-color: var(--table-row-divider-hover, var(--bg-first));
      }
    }

    tfoot {
      tr td {
        border-bottom: none;
      }
    }

    th {
      font-size: 16px;
      text-transform: uppercase;
      border: none;

      .mat-mdc-sort-header-content {
        position: sticky;
        left: 10px;
      }

      &.mat-mdc-table-sticky-border-elem-left ~ th {
        .mat-mdc-sort-header-content {
          left: 48px;
        }
      }

      .mat-mdc-sort-header-sorted {
        color: var(--primary);
      }

      &.mat-mdc-table-sticky {
        background: var(--table-bg, var(--bg-first));
      }
    }

    td {
      font-size: 15px;

      &.mat-mdc-footer-cell {
        padding-top: 10px;
        padding-bottom: 0;
        vertical-align: baseline;

        &.no-bg {
          background: none;
          padding-top: 20px;
        }

        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
        }

        vdms-hq-ui-dynamic-input {
          .form {
            display: flex;
            padding: 0 5px;
            width: 100%;

            & > div {
              width: 100%;
            }
          }
        }
      }
    }

    td,
    th {
      padding: 0 10px;
      word-break: keep-all;
      white-space: nowrap;

      &:first-of-type {
        padding-left: 12px;
      }

      &:last-of-type {
        padding-right: 12px;
        right: -1px !important;
      }

      @include media-queries.desktop-medium {
        &.cell-type-uuid {
          width: 300px;
        }
        &.cell-type-id {
          width: 50px;
        }
        &.cell-type-timecode {
          width: 100px;
          max-width: 200px;
        }
        &.column-select {
          width: 70px;
        }
        &.column-name {
          width: 350px;
        }
        &.column-status {
          width: 165px;
        }
        &.column-upload-status {
          width: 165px;
        }
        &.column-date {
          width: 140px;
        }
        &.column-icon-wide {
          width: 135px;
        }
        &.column-editable {
          width: 200px;
        }
        &.column-icon {
          width: 50px;
          text-align: center;

          &:first-of-type {
            padding: 0;
          }
        }
        &.mat-mdc-column-index {
          width: 50px;
        }
        &.mat-mdc-column-uuid {
          width: 50ch;
        }
      }

      &.actions {
        position: relative;
        z-index: 2;
      }
    }

    tbody tr:not(.disabled-row):hover {
      background: var(--table-body-bg-hover, var(--bg-third));

      td {
        color: var(--table-body-text-hover, var(--fg-base));
        background: var(--table-body-bg-hover, var(--bg-third));
      }
    }

    .mat-mdc-form-field-infix {
      width: initial;
      min-width: 80px;
    }
  }

  .table-advanced {
    &__row {
      &-actions {
        position: relative;
        overflow: visible;
      }

      &-actions-controls {
        opacity: 0;
        position: absolute;
        display: flex;
        justify-content: flex-end;
        right: 8px;
        top: 0;
        height: 100%;
        align-items: center;
      }

      &:hover {
        .table-advanced__row-actions-controls {
          opacity: 1;
        }
      }

      &.grey {
        --table-text: var(--table-text-placeholder);
      }

      @each $name, $border, $bg, $content, $isDashed in $pills {
        &.#{$name} > td:first-of-type {
          border-left: $border 2px solid;
          padding-left: 10px;
          @if $isDashed {
            border-left-style: dashed;
          }
        }
      }

      &.disabled-row {
        opacity: 0.7;

        .mat-column-status {
          filter: brightness(0.5);
        }
        .mat-column-select {
          pointer-events: none;
        }
      }
    }

    &__row-actions {
      width: 0;
    }
  }
}
