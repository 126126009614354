@use '@angular/material' as mat;

@mixin apply() {
  .mat-mdc-menu-panel {
    --mat-menu-container-color: var(--dropdown-menu-bg);
    @include mat.elevation(0);
    box-shadow: var(--shadow) !important;
    .mat-mdc-menu-content {
      padding: 0;
    }

    // apply colors to menu items / hover & focused state
    .mat-mdc-menu-item:hover:not([disabled]),
    .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
    .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
    .mat-mdc-menu-item-highlighted:not([disabled]) {
      background: var(--hover-bg);
      color: var(--hover-text);
    }
  }
}
