/**
 * @deprecated, use vida primary instead
 */
$blue: #5b45ff;

$white: #ffffff;
$vida-primary: #5b45ff;
$vida-accent: #8b7cff;

$dark-30: #0b0c0e;
$dark-25: #101113;
$dark-20: #151619;
$dark-10: #1e2025;
$dark: $dark-30;

$light-40: #2e3138;
$light-30: #4a4e58;
$light-20: #626673;
$light-10: #a4a9b7;
$light: $white;

$grey-5: #fafafa;
$grey-10: #f7f7f7;
$grey-15: #dddddd;
$grey-20: #cfcfcf;
$grey-25: #eaeaea;
$grey-30: #868686;
$grey: $grey-5;

$steel: #57595e;

$vida-white-dark: #a4a9b7;

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$alert: #c01d59;
$success: #05bb73;
$done: $success;
$warning: #e6ea00;
$info: $vida-primary;

$toast-warning: $warning;
$toast-info: $info;

$light-orange: #ffc58e;
$dark-orange: #834c0d;
$orange: #fc8c00;

$disabled-row-grey: #0b0d0e;

$dark-blue: #5b45ff;
$dark-blue-30: rgba(91, 69, 255, 0.3);

$cold-blue: #24c1cb;
$cold-blue-30: rgba(36, 193, 203, 0.3);

$orange: #f06500;
$orange-30: rgba(240, 101, 0, 0.3);

$orange-light: #ee922d;
$orange-light-30: rgba(238, 146, 45, 0.3);

$yellow: #eaa800;
$yellow-30: rgba(234, 168, 0, 0.3);

$done-light: #b9d133;
$done-light-30: rgba(185, 209, 51, 0.3);

$done: #05bb73;
$done-30: rgba(5, 187, 115, 0.3);

$failed: #bd2b3c;
$failed-30: rgba(189, 43, 60, 0.3);

$alert: #c01d59;
$alert-30: rgba(192, 29, 89, 0.3);

$pink: #f536ef;
$pink-30: rgba(245, 54, 239, 0.3);

$pending: #fc8c00;
$pending-30: rgba(252, 140, 0, 0.3);

$metadata-missing: #a52d79;
$metadata-missing-30: rgba(79, 31, 63, 0.3);
