@mixin apply() {
  input.mat-mdc-chip-input {
    flex: 1 0 100%;
    margin-left: 8px;
  }
  .mdc-evolution-chip__text-label {
    white-space: unset;
    text-overflow: unset;
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label,
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__icon--trailing {
    color: var(--secondary-text);
  }
}
