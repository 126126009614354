@use '@angular/material' as mat;

@mixin apply() {
  --input-label-scale: 0.75;
  --mdc-outlined-text-field-container-shape: var(--inputs-radius, 4px);
  --mat-select-container-elevation-shadow: var(--shadow);

  // set vars for overlays
  --mat-select-panel-background-color: var(--dropdown-menu-bg);
  --mat-select-enabled-arrow-color: var(--secondary-text);
  --mdc-checkbox-state-layer-size: 31px;
  --mdc-radio-state-layer-size: 31px;

  // apply styles to all fields
  .mat-mdc-form-field {
    // update custom font:
    margin: 0.25em 0;
    --mat-form-field-container-text-line-height: 24px;
    --mat-form-field-container-text-size: 16px;
    --mat-form-field-container-text-tracking: 1.5px;
    --mat-form-field-container-text-weight: 400px;

    --mat-mdc-form-field-floating-label-scale: var(--input-label-scale);
    --mdc-outlined-text-field-outline-color: var(--form-color-border);
    --mdc-outlined-text-field-focus-label-text-color: var(--text);
    --mdc-outlined-text-field-hover-outline-color: var(--text);
    --mdc-outlined-text-field-label-text-color: var(--fg-secondary-text);
    --mdc-outlined-text-field-disabled-input-text-color: var(--disabled-text);
    --mdc-outlined-text-field-disabled-label-text-color: var(--disabled-text);

    &.with-touched-indicator.ng-dirty.ng-touched {
      --mdc-outlined-text-field-outline-color: var(--accent);
    }
  }

  // apply color to chips
  .mat-mdc-standard-chip {
    --mdc-chip-disabled-label-text-color: var(--text);
    --mdc-chip-elevated-container-color: var(--bg-third);
    --mdc-chip-elevated-selected-container-color: var(--bg-third);
    --mdc-chip-elevated-disabled-container-color: var(--bg-third);
    --mdc-chip-flat-disabled-selected-container-color: var(--bg-third);
    --mdc-chip-focus-state-layer-color: var(--bg-third);
    --mdc-chip-hover-state-layer-color: var(--bg-third);
    --mdc-chip-selected-hover-state-layer-color: var(--bg-third);
    --mdc-chip-focus-state-layer-opacity: 0.12;
    --mdc-chip-selected-focus-state-layer-color: var(--bg-third);
    --mdc-chip-selected-focus-state-layer-opacity: 0.12;
    --mdc-chip-label-text-color: var(--text);
    --mdc-chip-selected-label-text-color: var(--text);
    --mdc-chip-with-icon-icon-color: var(--text);
    --mdc-chip-with-icon-disabled-icon-color: var(--text);
    --mdc-chip-with-icon-selected-icon-color: var(--text);
    --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--text);
    --mdc-chip-with-trailing-icon-trailing-icon-color: var(--text);
    --mat-chip-selected-disabled-trailing-icon-color: var(--text);
    --mat-chip-selected-trailing-icon-color: var(--text);
  }

  // apply color to toggle
  .mat-mdc-slide-toggle {
    --mdc-switch-unselected-focus-handle-color: var(--accent-light);
    --mdc-switch-unselected-active-handle-color: var(--accent-light);
    --mdc-switch-unselected-pressed-handle-color: var(--accent-light);
    --mdc-switch-unselected-hover-handle-color: var(--accent-light);
    --mdc-switch-selected-icon-color: var(--accent-text);
    --mdc-switch-selected-handle-color: var(--accent);
    --mdc-switch-selected-focus-handle-color: var(--accent);
    --mdc-switch-selected-hover-handle-color: var(--accent-dark);
    --mdc-switch-selected-hover-track-color: var(--accent-light);
    --mdc-switch-selected-pressed-track-color: var(--accent-light);
    --mdc-switch-selected-pressed-handle-color: var(--accent-dark);

    --mdc-switch-selected-focus-track-color: var(--accent-light);
    --mdc-switch-selected-track-color: var(--accent-light);
  }

  // apply color to checkbox inside selectors
  .mat-pseudo-checkbox {
    --mat-full-pseudo-checkbox-selected-checkmark-color: var(--primary-fg-text);
  }
  // apply color to checkbox
  .mdc-checkbox {
    --mdc-checkbox-disabled-selected-icon-color: var(--disabled-text);
    --mdc-checkbox-disabled-unselected-icon-color: var(--disabled-text);
    --mdc-checkbox-unselected-icon-color: var(--secondary-text);
    --mdc-checkbox-unselected-hover-icon-color: var(--text);
    --mdc-checkbox-unselected-focus-icon-color: var(--text);
    --mdc-checkbox-unselected-pressed-icon-color: var(--text);

    &--disabled {
      .mdc-form-field {
        label {
          color: var(--disabled-text);
        }
      }
    }
  }
  // apply color to radio
  .mdc-radio {
    --mdc-radio-disabled-selected-icon-color: var(--disabled-text);
    --mdc-radio-disabled-unselected-icon-color: var(--disabled-text);
    --mdc-radio-unselected-icon-color: var(--text);
    --mdc-radio-unselected-hover-icon-color: var(--text);
    --mdc-radio-unselected-focus-icon-color: var(--text);
    --mdc-radio-unselected-pressed-icon-color: var(--text);
  }

  // apply styles to select
  .mat-mdc-select-panel.mdc-menu-surface {
    padding: 0;

    &:not(.mat-paginator-panel) {
      max-height: 315px;
      min-width: 315px;
    }

    // focus:
    --mat-option-focus-state-layer-color: var(--hover-bg);
    .mat-mdc-option:focus,
    .mat-mdc-option-active {
      --mat-option-selected-state-label-text-color: var(--hover-text) !important;
      --mat-option-selected-state-layer-color: var(--hover-text);
    }

    // hover:
    --mat-option-hover-state-layer-color: var(--hover-bg);
    .mat-mdc-option:hover {
      --mat-option-selected-state-label-text-color: var(--hover-text);
      --mdc-list-list-item-hover-label-text-color: var(--hover-text);
    }

    .mat-mdc-option:not(.mat-mdc-option-multiple) {
      // for multiple we have checkboxes, no need to change background color
      --mat-option-selected-state-layer-color: var(--primary);
      --mat-option-selected-state-label-text-color: var(--primary-fg-text) !important;
      --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--primary-fg-text);
    }

    .cdk-virtual-scroll-content-wrapper {
      width: 100%;
    }
    .mat-mdc-option {
      letter-spacing: unset;

      .mdc-list-item__primary-text {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: var(--flex-gap, 8px);
        align-items: center;
        flex-grow: 1;

        .label {
          flex-shrink: 1;
          word-break: break-all;
        }
        .suffix {
          color: var(--secondary-text);
        }
      }
    }
  }

  .material-custom-field {
    width: var(--input-width, 100%);

    .suffix-button {
      cursor: pointer;
      padding: 0 12px;
    }

    .suffix-text {
      padding-right: 1rch;
      color: var(--secondary-text);
    }

    &.alternative-appearance {
      --mdc-outlined-text-field-outline-color: transparent;

      .mat-mdc-text-field-wrapper {
        background: var(--alternative-appearance-bg);

        .mat-mdc-form-field-outline {
          display: none;
        }

        .suffix {
          color: var(--secondary-text);
        }
      }
    }
  }

  // style timecode field
  .field-timecode {
    .mat-mdc-form-field-infix {
      max-width: 102px;

      input {
        text-align: center;
      }
    }

    .mdc-notched-outline {
      z-index: 3;
    }
  }

  // organise icons & buttons at the end of the input
  .mat-mdc-form-field-icon-suffix {
    white-space: nowrap;
    position: relative;
    display: flex;
    align-items: center;

    .suffix {
      margin: 0 8px;
    }
  }
  // organise icons & buttons at the start of the input
  .mat-mdc-form-field-icon-prefix {
    white-space: nowrap;
    flex: none;
    position: relative;

    .prefix {
      margin-left: 8px;
    }
  }

  .material-custom-field.textarea {
    .mat-mdc-form-field-icon-suffix {
      align-self: self-end;
      bottom: 0.5em;
    }
  }

  // apply styles to hint & error
  .mat-mdc-form-field-hint-wrapper {
    padding: 0 6px;
  }
  .hide-footer {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    .mdc-text-field {
      border-radius: var(--mdc-shape-small, 4px);
    }
  }

  // style fill appearance, for paginator i.e:
  .mat-form-field-appearance-fill {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background: transparent;

      .mdc-line-ripple::before {
        border-color: transparent;
      }
    }
  }

  .mat-button-toggle-group {
    --mat-standard-button-toggle-selected-state-background-color: var(--primary);
    --mat-standard-button-toggle-selected-state-text-color: var(--primary-fg-text);
    --mat-standard-button-toggle-background-color: var(--pill-bg);
    --mat-standard-button-toggle-label-text-size: 12px;
    --mat-standard-button-toggle-height: 16px;
    border: none;
    height: 24px;

    .mat-pseudo-checkbox-checked {
      display: none;
    }

    .mat-button-toggle-label-content {
      padding: 0 32px;
    }
  }

  // date picker
  .mat-calendar-body-in-range {
    --mat-datepicker-calendar-date-outline-color: var(--primary);
  }
}
