@mixin apply() {
  .material-custom-tab {
    --mat-tab-header-active-hover-label-text-color: var(--text);
    --mat-tab-header-active-label-text-color: var(--text);
    --mat-tab-header-active-focus-label-text-color: var(--text);

    .mdc-tab--active {
      --mat-tab-header-label-text-weight: 700;
    }
    &.mat-mdc-tab-group {
      width: 100%;
    }

    &.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
      flex-grow: 0;
    }

    .mat-mdc-tab-body-wrapper {
      padding: 1em 0 0;
    }

    .mdc-tab {
      padding: 0 20px;
    }

    .mat-mdc-tab.mdc-tab {
      height: 48px;
    }

    .mat-mdc-tab-label-container {
      border-bottom: 1px solid var(--divider);
    }
  }
}
