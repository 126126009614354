@use 'sass:map';
@use '@angular/material' as mat;
@use 'pallete';
@use 'vars';

@use 'common';
@use 'material/core' as mat-custom-core;
@use 'material/table' as mat-custom-table;
@use 'material/paginator' as mat-custom-paginator;
@use 'material/form-controls' as mat-custom-form-controls;
@use 'material/buttons' as mat-custom-buttons;
@use 'material/list' as mat-custom-list;
@use 'material/dialog' as mat-custom-dialog;
@use 'material/tabs' as mat-custom-tabs;
@use 'material/accordion' as mat-custom-accordion;
@use 'material/stepper' as mat-custom-stepper;
@use 'material/menu' as mat-custom-menu;
@use 'material/autocomplete' as mat-custom-autocomplete;
@use 'material/chips' as mat-custom-chips;
@use 'material/progress' as mat-custom-progress;
@use 'material/tooltip' as mat-custom-tooltip;

@import '3rd-party/previews';

@mixin vida-theme($light-class: 'theme-light', $font-cfg: false) {
  @if ($font-cfg == false) {
    $font-cfg: mat.define-typography-config(
      $font-family: 'Source Sans Pro',
      $headline-1: null,
      $headline-2: null,
      $headline-3: null,
      $headline-4: null,
      $headline-5: mat.define-typography-level(24px, 32px, 400, 'Source Sans Pro', 0em),
      $headline-6: mat.define-typography-level(20px, 32px, 500, 'Source Sans Pro', 0.0075em),
      $subtitle-1: mat.define-typography-level(16px, 28px, 400, 'Source Sans Pro', 0.0094em),
      $subtitle-2: mat.define-typography-level(15px, 24px, 500, 'Source Sans Pro', 0.0067em),
      // inputs:
      $body-1: mat.define-typography-level(16px, 24px, 400, 'Source Sans Pro', 1.5px),
      // body:
      $body-2: mat.define-typography-level(14px, 20px, 400, 'Source Sans Pro', 0.0179em),
      // smaller body, like hints, errors, etc.
      $caption: mat.define-typography-level(12px, 20px, 400, 'Source Sans Pro', 0.0333em),
      $button: mat.define-typography-level(16px, 22px, 500, 'Source Sans Pro', 0),
      $overline: null,
    );
  }

  $warn-palette: (
    lighter: var(--warn-light),
    main: var(--warn),
    darker: var(--warn-dark),
    contrast: (
      lighter: var(--warn-dark-text),
      main: var(--warn-light-text),
      darker: var(--warn-light-text),
    ),
  );

  $primary-palette: (
    lighter: var(--primary-light),
    main: var(--primary),
    darker: var(--primary-dark),
    contrast: (
      lighter: var(--primary-dark-text),
      main: var(--primary-light-text),
      darker: var(--primary-light-text),
    ),
  );

  $accent-palette: (
    lighter: var(--accent-light),
    main: var(--accent),
    darker: var(--accent-dark),
    contrast: (
      lighter: var(--accent-dark-text),
      main: var(--accent-light-text),
      darker: var(--accent-light-text),
    ),
  );

  $button-secondary: (
    lighter: var(--btn-secondary-light),
    main: var(--btn-secondary),
    darker: var(--btn-secondary-dark),
    contrast: (
      lighter: var(--btn-secondary-dark-text),
      main: var(--btn-secondary-text),
      darker: var(--btn-secondary-light-text),
    ),
  );

  $primary: mat.define-palette($primary-palette, 'main', 'lighter', 'darker');
  $accent: mat.define-palette($accent-palette, 'main', 'lighter', 'darker');
  $button-secondary: mat.define-palette($button-secondary, 'main', 'lighter', 'darker', 'main');
  $warn: mat.define-palette($warn-palette, 'main', 'lighter', 'darker');

  $light-theme: mat.define-light-theme(
    (
      color: (
        primary: $primary,
        accent: $accent,
        warn: $warn,
      ),
    )
  );
  $dark-theme: mat.define-dark-theme(
    (
      color: (
        primary: $primary,
        accent: $accent,
        warn: $warn,
      ),
    )
  );
  $button-dark-theme: mat.define-dark-theme(
    (
      color: (
        primary: $primary,
        accent: $button-secondary,
        warn: $warn,
      ),
    )
  );
  $button-light-theme: mat.define-light-theme(
    (
      color: (
        primary: $primary,
        accent: $button-secondary,
        warn: $warn,
      ),
    )
  );

  $dark-theme: map.set(
    $dark-theme,
    'color',
    'background',
    (
      status-bar: var(--bg-status-bar),
      app-bar: var(--bg-app-bar),
      background: pallete.$dark-30,
      hover: var(--bg-hover),
      card: pallete.$dark-30,
      dialog: var(--bg-dialog),
      disabled-list-option: var(--bg-disabled-list-option),
    )
  );
  $light-theme: map.set(
    $dark-theme,
    'color',
    'background',
    (
      status-bar: var(--bg-status-bar),
      app-bar: var(--bg-app-bar),
      background: pallete.$white,
      hover: var(--bg-hover),
      card: pallete.$white,
      dialog: var(--bg-dialog),
      selected-button: var(--bg-selected-button),
      disabled-list-option: var(--bg-disabled-list-option),
    )
  );

  $theme-foreground-palette: (
    base: var(--fg-base),
    divider: var(--divider),
    dividers: var(--secondary-divider),
    disabled: var(--fg-disabled),
    elevation: var(--fg-elevation),
    hint-text: var(--fg-hint-text),
    secondary-text: var(--fg-secondary-text),
    icon: var(--fg-icon),
    icons: var(--fg-icons),
    text: var(--fg-text),
    slider-min: var(--fg-slider-min),
    slider-off: var(--fg-slider-off),
    slider-off-active: var(--fg-slider-off-active),
  );

  $dark-theme: map.set($dark-theme, 'color', 'foreground', $theme-foreground-palette);
  $light-theme: map.set($light-theme, 'color', 'foreground', $theme-foreground-palette);

  $button-dark-theme: map.set(
    $button-dark-theme,
    'color',
    'background',
    (
      disabled-button: var(--bg-disabled-button),
      raised-button: var(--bg-raised-button),
      focused-button: var(--bg-focused-button),
      selected-button: var(--bg-selected-button),
      selected-disabled-button: var(--bg-selected-disabled-button),
      disabled-button-toggle: var(--bg-disabled-button-toggle),
    )
  );

  $button-light-theme: map.set(
    $button-light-theme,
    'color',
    'background',
    (
      disabled-button: var(--bg-disabled-button),
      raised-button: var(--bg-raised-button),
      focused-button: var(--bg-focused-button),
      selected-button: var(--bg-selected-button),
      selected-disabled-button: var(--bg-selected-disabled-button),
      disabled-button-toggle: var(--bg-disabled-button-toggle),
    )
  );

  html,
  body {
    height: 100%;
  }
  * {
    box-sizing: border-box;
  }

  // try remove this line on next update, for some reason all-component-colors causes duplication warnings
  mat.$theme-ignore-duplication-warnings: true;

  @include mat.core();
  @include mat.all-component-typographies($font-cfg);
  @include mat.all-component-densities(-3);
  @include mat.form-field-density(-5);
  @include mat.button-density(0);

  body {
    margin: 0;
    @include vars.shared-vars();
    @include vars.dark-vars();

    @include mat.all-component-colors($dark-theme);
    @include mat.button-color($button-dark-theme);

    &.#{$light-class} {
      @include vars.light-vars();
      @include mat.all-component-colors($light-theme);
      @include mat.button-color($button-light-theme);
    }
  }

  body,
  body.#{$light-class} {
    @include common.styles();
    @include mat-custom-core.apply();
    @include mat-custom-table.apply();
    @include mat-custom-paginator.apply();
    @include mat-custom-form-controls.apply();
    @include mat-custom-buttons.apply();
    @include mat-custom-list.apply();
    @include mat-custom-dialog.apply();
    @include mat-custom-tabs.apply();
    @include mat-custom-accordion.apply();
    @include mat-custom-stepper.apply();
    @include mat-custom-menu.apply();
    @include mat-custom-autocomplete.apply();
    @include mat-custom-chips.apply();
    @include mat-custom-progress.apply();
    @include mat-custom-tooltip.apply();
  }

  ::-webkit-scrollbar {
    background: var(--scrollbar-bg);
    width: 6px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--scrollbar-handle);
    border-radius: 4px;
  }

  // white vida logo on navbar
  body.#{$light-class} .ui-sn__navbar {
    img.logo:not(.netflix-logo),
    img.logo-vida {
      filter: brightness(0.3) contrast(1.5);
    }
  }

  body {
    input[type='search']::-webkit-search-cancel-button {
      -webkit-appearance: none;
      height: 16px;
      width: 16px;
      background: url('/assets/common/icons/close-x-dark.svg') no-repeat center center;
      background-size: contain;
      cursor: pointer;
    }

    &.#{$light-class} {
      input[type='search']::-webkit-search-cancel-button {
        -webkit-appearance: none;
        height: 16px;
        width: 16px;
        background: url('/assets/common/icons/close-x-light.svg') no-repeat center center;
        background-size: contain;
        cursor: pointer;
      }
    }
  }
}
