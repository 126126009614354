@mixin apply() {
  .mat-stepper-horizontal {
    padding: 24px 0 !important;

    .mat-horizontal-stepper-header-container {
      margin-inline: auto;

      .mat-horizontal-stepper-header {
        width: 200px;
      }
    }

    .mat-horizontal-content-container {
      padding: 0 0 16px;
    }
  }
}
