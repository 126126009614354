@use '@angular/material' as mat;

@mixin apply() {
  .mat-expansion-panel {
    border-bottom: 1px solid var(--divider, #2e3138);
    @include mat.elevation(0);
    .mat-expansion-panel-header {
      padding: 0 8px;
      .mat-content {
        justify-content: space-between;
        .mat-expansion-panel-header-title,
        .mat-expansion-panel-header-description {
          flex-grow: 0;
          min-width: fit-content;
        }
      }
    }

    .mat-expansion-panel-content {
      padding: 0 8px;
    }

    .mat-expansion-panel-body {
      padding: 0 0 16px;
    }
  }
}
