@import 'media-queries';
@import 'pallete';

@mixin barTheme($color, $background) {
  border: 1px solid var($color);
  background: $background;

  mat-icon.message-icon {
    color: var($color);
  }
}

@mixin styles() {
  $space-small: 0.6em;
  $space-medium: 1em;
  $space-large: 1.4em;
  $space-huge: 2.4em;

  $spaces:
    'small' $space-small,
    'medium' $space-medium,
    'large' $space-large,
    'huge' $space-huge;

  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .margin-inline-auto {
    margin-inline: auto;
  }

  .margin-block {
    margin-block: auto;
    &-1 {
      margin-block: 1rem;
    }
  }

  .text-center {
    text-align: center;
  }

  .align {
    &-center {
      align-items: center;
    }
    &-start {
      align-items: flex-start;
    }
    &-end {
      align-items: flex-end;
    }
    &-self-center {
      align-self: center;
    }
  }

  .justify {
    &-between {
      justify-content: space-between;
    }
    &-end {
      justify-content: flex-end;
    }
    &-self-center {
      justify-self: center;
    }
  }

  .fit-inputs {
    .mat-form-field,
    .mat-slider {
      width: 100%;
    }
  }

  .d-block {
    display: block;
  }

  .d-flex {
    display: flex;
  }

  .w100 {
    width: 100%;
  }

  .w50 {
    width: 50%;
  }

  .w30 {
    width: 30%;
  }

  .mw {
    &-600 {
      max-width: 600px;
    }
  }

  .align-center {
    &-center {
      align-items: center;
    }
    &-start {
      align-items: flex-start;
    }
  }
  .justify-center {
    display: flex;
    justify-content: center;
  }

  .flex-col {
    display: flex;
    flex-direction: column;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .flex-gap {
    gap: calc(var(--flex-gap) / 2);

    &-2 {
      gap: 2rem;
    }

    &-3 {
      gap: 3rem;
    }
  }

  .mt {
    &-auto {
      margin-top: auto;
    }
    &-1 {
      margin-top: 1rem !important;
    }
    &-40px {
      margin-top: 40px;
    }
  }

  .mb {
    &-0 {
      margin-bottom: 0 !important;
    }
    &-1 {
      margin-bottom: 1rem !important;
    }
  }

  .pt-0 {
    padding-top: 0 !important;
  }

  .pt-16 {
    padding-top: 16px !important;
  }

  .pb-1 {
    padding-bottom: 1rem !important;
  }

  .ml-auto {
    margin-left: auto;
  }

  .mr-auto {
    margin-right: auto;
  }

  .mh-15vh {
    min-height: 15vh;
  }

  .border-right {
    border-right: 1px solid $light-20;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr;

    &.grid-gap-x {
      grid-column-gap: 0.5em;
    }

    &.grid-gap-x-double {
      grid-column-gap: 1em;
    }

    &.grid-gap-x-wide {
      grid-column-gap: 2em;
    }

    &.grid-gap-y {
      grid-row-gap: 0.5em;
    }

    &.grid-gap-y-double {
      grid-row-gap: 1em;
    }

    &.grid-upto2 {
      @include desktop-large {
        grid-template-columns: 1fr 1fr;
      }
    }

    &.grid-upto3 {
      @include desktop-medium {
        grid-template-columns: 1fr 1fr;
      }
      @include desktop-large {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    &.grid-upto4 {
      grid-template-columns: 1fr 1fr;
      @include desktop-medium {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      @include desktop-large {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }

    &.grid-upto5 {
      grid-template-columns: 1fr 1fr;
      @include desktop-medium {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }
      @include desktop-large {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }
    }
  }

  $paddings:
    'pt' 'padding-top',
    'pb' 'padding-bottom',
    'pl' 'padding-left',
    'pr' 'padding-right';

  $margins:
    'mt' 'margin-top',
    'mb' 'margin-bottom',
    'ml' 'margin-left',
    'mr' 'margin-right';

  @each $size-name, $size in $spaces {
    @each $padding-name, $property-name in $paddings {
      .#{$padding-name}-#{$size-name} {
        #{$property-name}: $size;
      }
    }
    .padding-#{$size-name} {
      @each $padding-name, $property-name in $paddings {
        #{$property-name}: $size;
      }
    }
    @each $margin-name, $property-name in $margins {
      .#{$margin-name}-#{$size-name} {
        #{$property-name}: $size;
      }
    }
    .margin-#{$size-name} {
      @each $margin-name, $property-name in $margins {
        #{$property-name}: $size;
      }
    }
  }

  .relative {
    position: relative;
  }

  .p-scroll {
    padding-right: 10px;
  }

  .title-error-msg {
    color: $alert;
    font-weight: 500;
    font-size: 1rem;
    padding-left: 8px;
    margin-left: 8px;
    border-left: 1px solid $light-20;
  }

  .multi-data-container {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: var(--flex-gap, 8px);
  }

  .primary-href {
    color: var(--primary);
  }

  .w-full {
    width: 100%;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .buttons-group {
    display: flex;
    justify-content: space-between;
    gap: var(--flex-gap, 8px);
    &.start {
      justify-content: flex-start;
    }
  }

  .link {
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;
  }
}
