@import 'pallete';

@mixin shared-vars() {
  --primary-light: #987fff;
  --primary: #{$vida-primary};
  --primary-dark: #3138ef;
  --primary-fg-text: #fff;
  --primary-light-text: #fff;
  --primary-dark-text: #fff;

  --accent-light: #b0a3fe;
  --accent: #{$vida-accent};
  --accent-dark: #7666e1;
  --accent-text: #fff;
  --accent-light-text: #fff;
  --accent-dark-text: #fff;

  --warn-light: #{$alert};
  --warn: #{$alert};
  --warn-dark: #{$alert};
  --warn-fg-text: #fff;
  --warn-light-text: #fff;
  --warn-dark-text: #fff;

  --color-alert: #{$alert};
  --color-pending: #{$pending};
  --color-done: #{$done};
  --color-active: #{$info};

  --highlight-js: #{$vida-white-dark};

  --selectable-neon-rotate: 132deg;
  --selectable-neon-bg: linear-gradient(var(--selectable-neon-rotate), #3d75f3 0%, #af7dff 100%);

  --bg-disabled-button-toggle: black;
  --bg-disabled-list-option: black;
  --bg-status-bar: transparent;
  --bg-focused-button: transparent;
  --bg-selected-button: transparent;
  --bg-hover: var(--bg-third);
  --fg-base: var(--text);
  --fg-disabled: var(--disabled-text);
  --fg-disabled-button: var(--disabled-text);
  --fg-disabled-text: var(--disabled-text);
  --fg-hint-text: var(--secondary-text);
  --fg-secondary-text: var(--secondary-text);
  --fg-icon: var(--text);
  --fg-icons: var(--text);
  --fg-text: var(--text);
  --fg-slider-min: var(--secondary-text);
  --fg-slider-off: var(--disabled-text);
  --fg-slider-off-active: var(--primary);

  --destination-bg: #{$dark-10};

  --new-field-border: #319e0b;
  --new-background: #1e550c;

  --table-higlighted: #{$orange};
  --navbar-height: 64px;
  --action-bar-height: 85px;

  // layout
  --page-header-padding: 24px;
  --page-filters-padding: 24px;
  --page-table-padding: 24px;

  --flex-gap: 8px;

  --radius-sm: 4px;
  --inputs-radius: 4px;
  --buttons-radius: 4px;
  --radius-md: 8px;
  --radius-xl: 16px;

  --shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.1), 0px 8px 10px 1px rgba(0, 0, 0, 0.12),
    0px 3px 14px 2px rgba(0, 0, 0, 0.08);
}

@mixin light-vars() {
  --bg-first: #{$white};
  --bg-second: #{$grey-5};
  --bg-third: #{$grey-20};

  --btn-secondary-light: #5d5e65;
  --btn-secondary: #{$steel};
  --btn-secondary-dark: #3c3d42;

  --btn-secondary-text: #fff;
  --btn-secondary-light-text: #fff;
  --btn-secondary-dark-text: #fff;

  --btn-toggle-bg: rgba(222, 222, 222, 0.6);

  --highlight-js: #800;

  --side-menu-bg: #{$grey-5};
  --side-menu-fg: #{$dark-10};
  --side-menu-fg-active: var(--text);

  --text-hover: #fff;
  --text: #{$dark-30};
  --text-lighter: #{$dark-20};
  --text-shadow: rgba(255, 255, 255, 0.5);

  --secondary-text: #{$light-20};
  --secondary-light-text: rgba($light-20, 0.1);
  --disabled-text: #{$light-10};

  --form-color-border: #{$grey-30};
  --form-color-empty-label: #{$dark-10};

  --bg-app-bar: #{$grey-10};
  --bg: #{$white};
  --bg-card: #{$white};
  --bg-dialog: var(--bg-first);
  --bg-dialog-footer: #{$grey-10};
  --bg-raised-button: #{$grey-10};
  --bg-unselected-chip: #{$grey-10};
  --bg-tooltip: #{$grey-15};
  --bg-upload: #{$grey-10};
  --bg-chip: #{$grey-15};

  --dropdown-menu-bg: #fbfbfb;
  --dropdown-menu-border: #e8e8e8;
  --dropdown-menu-text: var(--text);

  --bg-disabled-button: rgba(0, 0, 0, 0.12);
  --bg-selected-disabled-button: rgba(0, 0, 0, 0.12);

  --divider: #{$grey-20};
  --secondary-divider: #{$grey-10};

  --divider-th: #{$light-40};
  --divider-td: #{$dark-10};

  --input-bg: #{$grey-10};
  --progress-fg: #{$vida-accent};
  --progress-bg: #{$light-30};

  --table-text: #{$light-30};
  --table-text-selected: black;
  --table-text-placeholder: #{$light-10};
  --table-column-divider: #{$grey-15};

  --table-simple-row-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

  --footer-bar-bg: #{$grey-5};
  --footer-bar-shadow: #{$grey-20};

  --footer-bar-button-container: #{$grey-25};

  --toast-info: #{$toast-info};
  --toast-warning: #{$toast-warning};
  --toast-error: #{$alert};
  --toast-success: #{$done};

  --scrollbar-bg: #{$grey-25};
  --scrollbar-handle: #{$grey-30};

  --select-option-hover: #{$grey-20};

  --btn-slide-checked-background: #{$light-orange};
  --btn-slide-unchecked-background: #{$grey-30};

  --btn-slide-unchecked-dot: #{$grey-15};
  --alternative-appearance-bg: #{$grey-10};

  --navbar-fg: #{$dark};
  --navbar-bg: #{$white};

  --pill-text-color: #{$dark-10};
  --pill-bg: #{$grey-15};
  --pill-border: #{$light-30};

  --destination-bg: #{$grey-15};
  --active-metadata-element: #{$grey-20};

  --video-shadow: linear-gradient(to bottom, transparent 0%, rgb(255 255 255 / 82%) 100%);

  --share-dialog-tile-bg: #efefef;
  --share-dialog-tile-bg-selected: rgba(239, 239, 239, 0.3);
  --share-dialog-tile-bg-hover: #fafafa;
  --selectable-tile-bg-hover: rgba(164, 169, 183, 0.3);

  --grid-tile-bg: #fafafa;
  --grid-tile-hover-bg: #f7f7f7;
  --grid-tile-select: #cfcfcf;

  --loader-background-color: rgba(255, 255, 255, 0.01);
  --vida-blue: #{$vida-primary};

  --search-hero-border-color: #000;

  --hover-bg: #{$grey-20};
  --hover-text: #{$dark-30};
}

@mixin dark-vars() {
  --bg-first: #{$dark-30};
  --bg-second: #{$dark-20};
  --bg-third: #{$dark-10};

  --btn-secondary-light: #{$light-30};
  --btn-secondary: #{$light-40};
  --btn-secondary-dark: #3c3d42;

  --btn-secondary-text: #fff;
  --btn-secondary-light-text: #fff;
  --btn-secondary-dark-text: #fff;

  --btn-toggle-bg: rgba(11, 12, 14, 0.6);

  --highlight-js: #{$vida-white-dark};

  --side-menu-bg: #{$dark-10};
  --side-menu-fg: #{$light-10};
  --side-menu-fg-active: var(--text);

  --text: #{$white};
  --text-lighter: #{$grey-5};
  --text-shadow: rgba(0, 0, 0, 0.5);
  --secondary-text: #{$light-10};
  --secondary-light-text: rgba($light-10, 0.1);
  --disabled-text: #{$light-20};

  --form-color-border: #{$light-10};
  --form-color-empty-label: #{$light-10};

  --bg-app-bar: #{$dark-20};
  --bg: #{$dark};
  --bg-card: #{$dark-30};
  --bg-dialog: var(--bg-first);
  --bg-dialog-footer: #{$dark-10};
  --bg-raised-button: #{$light-40};
  --bg-unselected-chip: #{$dark-10};
  --bg-tooltip: #{$light-40};
  --bg-upload: #{$dark-20};
  --bg-chip: #{$dark-20};

  --dropdown-menu-bg: var(--bg-third);
  --dropdown-menu-border: #{$light-40};
  --dropdown-menu-text: var(--text);

  --bg-disabled-button: rgba(255, 255, 255, 0.12);
  --bg-selected-disabled-button: rgba(255, 255, 255, 0.12);

  --divider: #{$light-40};
  --secondary-divider: #{$light-20};
  --input-bg: #{$light-40};
  --progress-fg: #{$vida-accent};
  --progress-bg: #{$light-30};

  --table-text: #{$light-10};
  --table-text-selected: $light;
  --table-text-placeholder: #{$light-30};
  --table-column-divider: #{$dark-10};

  --table-simple-row-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.9);

  --footer-bar-bg: #{$dark-20};
  --footer-bar-shadow: #{$dark-30};

  --footer-bar-button-container: #{$dark-25};

  --toast-info: #{$toast-info};
  --toast-warning: #{$toast-warning};
  --toast-error: #{$alert};
  --toast-success: #{$done};

  --scrollbar-bg: #{$dark-10};
  --scrollbar-handle: #{$light-20};

  --select-option-hover: #{$light-40};

  --btn-slide-checked-background: #{$dark-orange};
  --btn-slide-unchecked-background: #{$grey-30};

  --btn-slide-unchecked-dot: #{$grey-15};
  --alternative-appearance-bg: #{$dark-10};

  --pill-text-color: #{$light};
  --pill-bg: #{$dark-10};
  --pill-border: #{$light-40};

  --destination-bg: #{$dark-10};
  --active-metadata-element: #{$light-40};
  --video-shadow: linear-gradient(to bottom, transparent 0%, rgba(11, 12, 14, 0.8) 100%);

  --share-dialog-tile-bg: #{$dark-10};
  --share-dialog-tile-bg-selected: rgba(30, 32, 37, 0.3);
  --share-dialog-tile-bg-hover: #{$light-40};
  --selectable-tile-bg-hover: #{$light-40};
  --grid-tile-bg: #151619;
  --grid-tile-hover-bg: #1e2025;
  --grid-tile-select: #cfcfcf;

  --loader-background-color: rgba(0, 0, 0, 0.01);
  --search-hero-border-color: #fff;
  --vida-blue: #{$vida-primary};

  --hover-bg: #{$light-40};
  --hover-text: #{$white};
}
