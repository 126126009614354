@mixin apply() {
  --mat-autocomplete-container-elevation-shadow: var(--shadow);

  // apply styles to all autocomplete lists
  .mat-mdc-autocomplete-panel.mdc-menu-surface {
    --mat-autocomplete-background-color: var(--dropdown-menu-bg);
    --mat-option-focus-state-layer-color: var(--hover-bg);
    --mat-option-focus-state-layer-text-color: var(--hover-text);
    --mat-option-hover-state-layer-color: var(--hover-bg);
    --mat-option-hover-state-layer-text-color: var(--hover-text);

    --mat-option-selected-state-layer-color: var(--primary);
    --mat-option-selected-state-label-text-color: var(--primary-fg-text);

    --mdc-list-list-item-container-color: var(--primary);
    --mdc-list-list-item-hover-label-text-color: var(--hover-text);

    padding: 0;
    .mat-mdc-option {
      &.mat-mdc-option-active {
        --mdc-list-list-item-label-text-color: var(--primary-fg-text);
      }
      .mdc-list-item__primary-text {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: baseline;
      }
    }
  }

  // apply custom styles to autocomplete list (ui-selectable)
  .mat-mdc-autocomplete-panel {
    .mat-mdc-option.selectable-list-autocomplete-option {
      padding: 0;
      .autocomplete-option {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        width: 100%;
        height: 100%;
        padding: 0.5rem;
        cursor: pointer;

        text-decoration: none;
        gap: 1rem;
        pointer-events: auto;
        position: relative;

        &:hover {
          .autocomplete-option__label {
            color: rgba(255, 255, 255, 0.8);
          }
        }

        &.selected {
          border-left: 2px solid var(--color-done);
        }

        &.disabled {
          opacity: 0.3;
          cursor: not-allowed;
        }

        &__title {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          display: inline-block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          border-right: 1px solid var(--secondary-divider);
          padding-right: 1rem;
          max-width: 50ch;
        }

        &__metadata {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 6px;
          & > * {
            display: flex;
            gap: 3px;
            flex-wrap: wrap;
          }
          & > *:not(:last-of-type) {
            border-right: 1px solid var(--secondary-divider);
            padding-right: 8px;
          }
          & > *.w-full {
            border: none;
            padding-right: unset;
          }
        }

        &__label {
          color: var(--secondary-text);
        }

        .mat-mdc-icon {
          font-size: 12px;
          height: 12px;
          text-align: center;

          &.blocked {
            //color: $alert;
          }
        }
      }
    }
  }
}
