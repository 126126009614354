.doc-reset-styles {
  .docx-wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    min-width: 600pt;
    background: none;
    padding: 0;
    margin: 0;
  }

  .docx-wrapper > section.docx {
    box-shadow: none;
    padding: 0;
    margin: 0;
  }
}

.sheet-reset-styles {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    max-width: 100%;
  }

  th,
  td {
    padding: 0.15em 0.5em;
    border: 1px solid #bfbfbf52;
  }
}

.video-js {
  .vjs-play-progress {
    background-color: var(--primary) !important;
  }
  .vjs-texttrack-settings {
    display: none;
  }
  .vjs-big-play-button {
    z-index: 15;
  }
}
