@mixin apply() {
  --mat-paginator-disabled-icon-color: var(--disabled-text);
  .mat-paginator-panel {
    --mat-option-label-text-size: 12px;
    --mat-option-label-text-line-height: 16px;
  }
  .mat-mdc-paginator {
    background: transparent;
  }

  .mat-mdc-paginator-container {
    margin-top: 8px;
    .mat-mdc-paginator-page-size-label,
    .mat-mdc-paginator-range-label {
      color: var(--secondary-text);
      font-size: 12px;
    }
  }
}
